import {SendAuthCode} from '../api/index'

const pubFun = {
 //监听屏幕尺寸
 getWidth() {
  let cWidth = window.innerWidth;
  if (cWidth > 740) {
   return false;
  } else {
   return true;
  }
 },
 //返回上一页
 goBack() {
  window.history.go(-1);
 },

 /**
  * 发送验证码
  */
 getSendAuthCode(phone) {
  if (!phone) {
   window.instanceObj.$message.warning('请输入手机号')
   return
  }

  if (!(/^1[34578]\d{9}$/.test(phone))) {
   window.instanceObj.$message.warning('手机号格式有误请重新输入')
   return;
  }

  const param = {
   phone
  }

  SendAuthCode(param).then(response => {
   if (response.Code === 0) {
    window.instanceObj.$message.success('验证码发送成功！')
   } else {
    window.instanceObj.$message.error(response.Message)
   }
  })
 },
};

export default pubFun;
