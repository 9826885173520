<template>
  <div id="app">
    <main-header></main-header>
    <router-view/>
    <main-foot></main-foot>
  </div>
</template>

<script>
import mainHeader from "@/components/header.vue"
import mainFoot from "@/components/foot.vue"

export default {
  name: 'app',
  components: {
    mainHeader,
    mainFoot
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
