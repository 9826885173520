import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if(to.path != '/solution'){
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/index',//设置默认指向的路径
      name: '首页'
    }, 
    {
      path: '/index',
      component: resolve => require.ensure([], () => resolve(require('@/pages/index/index.vue')), 'index'),
      name: 'index',
      meta: { title: '首页' }
    },
    {
      path: '/about',
      component: resolve => require.ensure([], () => resolve(require('@/pages/about/index.vue')), 'about'),
      name: 'about',
      meta: { title: '关于我们' }
    },
    {
      path: '/solution',
      component: resolve => require.ensure([], () => resolve(require('@/pages/solution/index.vue')), 'about'),
      name: 'solution',
      meta: { title: '解决方案' }
    },
    {
      path: '/news',
      component: resolve => require.ensure([], () => resolve(require('@/pages/news/index.vue')), 'news'),
      name: 'news',
      meta: { title: '资讯动态' }
    },
    {
      path: '/newsDetail',
      component: resolve => require.ensure([], () => resolve(require('@/pages/newsDetail/index.vue')), 'newsDetail'),
      name: 'newsDetail',
      meta: { title: '资讯动态详情' }
    },
    {
      path: '/recruit',
      component: resolve => require.ensure([], () => resolve(require('@/pages/recruit/index.vue')), 'recruit'),
      name: 'recruit',
      meta: { title: '人才招聘' }
    },
    {
      path: '/recruitDetail',
      component: resolve => require.ensure([], () => resolve(require('@/pages/recruitDetail/index.vue')), 'recruitDetail'),
      name: 'recruitDetail',
      meta: { title: '招聘详情' }
    },
    {
      path: '/case',
      component: resolve => require.ensure([], () => resolve(require('@/pages/case/index.vue')), 'case'),
      name: 'case',
      meta: { title: '案例' }
    },
    {
      path: '/qljySolution',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/qljySolution/index.vue')), 'qljySolution'),
      name: 'qljySolution',
      meta: { title: '青蓝家园教育解决方案' }
    },
    {
      path: '/showHow',
      component: resolve => require.ensure([], () => resolve(require('@/pages/showHow/index.vue')), 'showHow'),
      name: 'showHow',
      meta: { title: '实例演示' }
    },
    {
      path: '/t3c',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/t3c/index.vue')), 't3c'),
      name: 't3c',
      meta: { title: '唐三彩' }
    },
    {
      path: '/tuplesFood',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/tuplesFood/index.vue')), 'tuplesFood'),
      name: 'tuplesFood',
      meta: { title: '元祖食品' }
    },
    {
      path: '/watson',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/watson/index.vue')), 'watson'),
      name: 'watson',
      meta: { title: '屈臣氏' }
    },
    {
      path: '/westernLiverpool',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/westernLiverpool/index.vue')), 'westernLiverpool'),
      name: 'westernLiverpool',
      meta: { title: '西交利物浦' }
    },
    { // 未
      path: '/daysLion',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/daysLion/index.vue')), 'daysLion'),
      name: 'daysLion',
      meta: { title: '天狮集团' }
    },
    {
      path: '/boShiLun',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/boShiLun/index.vue')), 'boShiLun'),
      name: 'boShiLun',
      meta: { title: '博士伦' }
    },
    {
      path: '/ja',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/ja/index.vue')), 'ja'),
      name: 'ja',
      meta: { title: 'ja' }
    },
    {
      path: '/jianye',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/jianye/index.vue')), 'jianye'),
      name: 'jianye',
      meta: { title: '建业' }
    },
    {
      path: '/newEnergy',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/newEnergy/index.vue')), 'newEnergy'),
      name: 'newEnergy',
      meta: { title: '优得新能源' }
    },
    {
      path: '/hengtong',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/hengtong/index.vue')), 'hengtong'),
      name: 'hengtong',
      meta: { title: '亨通光电' }
    },
    { // 未
      path: '/spectrum',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/spectrum/index.vue')), 'spectrum'),
      name: 'spectrum',
      meta: { title: '光谱分析仪解决方案' }
    },
    { 
      path: '/wanKe',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/wanKe/index.vue')), 'wanKe'),
      name: 'wanKe',
      meta: { title: '万科 - 美好家定制家装' }
    },
    { 
      path: '/gaoDi',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/gaoDi/index.vue')), 'gaoDi'),
      name: 'gaoDi',
      meta: { title: '高地物业' }
    },
    { 
      path: '/ngs',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/ngs/index.vue')), 'ngs'),
      name: 'ngs',
      meta: { title: '农工商' }
    },
    { // 未
      path: '/shengRanAnn',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/shengRanAnn/index.vue')), 'shengRanAnn'),
      name: 'shengRanAnn',
      meta: { title: '晟然为安' }
    },
    { 
      path: '/youBang',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/youBang/index.vue')), 'youBang'),
      name: 'youBang',
      meta: { title: '友邦保险' }
    },
    { // 未
      path: '/kunShanSports',
      component: resolve => require.ensure([], () => resolve(require('@/pages/caseDetail/kunShanSports/index.vue')), 'kunShanSports'),
      name: 'kunShanSports',
      meta: { title: '昆山体育局' }
    },
    { // 未
      path: '/privacy',
      component: resolve => require.ensure([], () => resolve(require('@/pages/privacy.vue')), 'privacy'),
      name: 'privacy',
      meta: { title: '隐私声明' }
    },
  ]
})
