import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import pubFun from '@/utils/pubFun';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'
import { Upload, Select, Option, Dialog, Form, FormItem, Input, Message } from 'element-ui';

Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)

// Vue.prototype.$url = 'http://rd.mmbbsay.com:8802/';
Vue.prototype.$url = 'http://www.rdinfo.com.cn:8100/';

Vue.config.productionTip = false
Vue.prototype.$pubFun = pubFun
Vue.prototype.$message = Message;


const instance = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

window.instanceObj = instance;
