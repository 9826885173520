import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    isPhone:false,//公共导航栏
  },
  mutations: {
    //公共导航栏
    changeNavStatus(state, status) {
      state.isPhone = status;
    },
  },
  actions: {

  },
});
export default store;
