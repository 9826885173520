<template>
  <div class="foot">
    <img class="line" src="../assets/images/line.png" alt="">
    <div class="tip">沟通，从这里开始</div>
    <div class="form">
      我想了解：
      <el-input type="textarea" :autosize="{maxRows: 3}" maxlength="100" v-model="formNeed" placeholder="请输入您的需求"></el-input>
      我的联系方式：
      <el-input v-model="formPhone" placeholder="请输入您的手机号" ></el-input>
      <div class="book" @click="setAddAppointment">预约咨询</div>
    </div>
    <div class="hot">咨询热线：+86-512-68093097</div>
    <div class="footGuide">
      <div class="footGuideContact">
        <div class="footGuideContactTitle">联系我们</div>
        <div class="footGuideContactInfo">邮箱地址：hr@rdinfo.com.cn</div>
        <div class="footGuideContactInfo">办公地址：中国江苏省苏州市竹园路209号</div>
      </div>
      <div class="footGuidenNav">
        <div v-for="(item, i) in footNavData" :key="'foot'+i">
          <div @click="setLinkFun(item.path, item.id)">{{item.name}}</div>
          <div class="footGuidenNavsub" v-for="(sub, j) in item.children" :key="'sub'+j" @click="setLinkFun(sub.path, sub.id)">{{sub.name}}</div>
        </div>
      </div>
    </div>
    <div class="Copyright">
      <div style="margin-right: 5px;cursor: pointer;position:relation;z-index: 999;" @click="goPrivacy">隐私声明 | </div>
      <div>Copyright 2010 RD information technology Co.,ltd. All Rights Reserved.</div>
      <div>苏ICP备12010731号-1</div>
    </div>
  </div>
</template>

<script>
  import {AddAppointment} from '../api/index'
export default {
  name: 'foot',
  data () {
    return {
      formNeed: '',
      formPhone:'',
      footNavData: [
        {
          name: '首页',
          path: '/index'
        },
        {
          name: '解决方案',
          path: '/solution', id: 'block0',
          children:[
            {path: '/solution', id: 'block0', name:'智慧教育'},
            {path: '/solution', id: 'block1', name:'智慧零售'},
            {path: '/solution', id: 'block4', name:'数字化营销'}
            ]
        },
        {
          name: '精选案例',
          path: '/case', id: '0',
          children:[
            {path: '/case', id: '11', name:'保险'},
            {path: '/case', id: '7', name:'医疗'},
            {path: '/case', id: '12', name:'其他'}
          ]
        },
        {
          name: '实例演示',
          path: '/showHow', id: '1',
          children:[
            {path: '/showHow', id: '3',name: '会议管理文件系统'},
            {path: '/showHow',id: '1', name: '竞价系统'},
            {path: '/showHow',id: '2', name: '公益平台'}
          ]
        },
        {
          name: '关于我们',
          path: '/about',
          children:[
            {path: '/about',name:'企业介绍'},
            {path: '/news',name:'资讯动态'}
          ]
        },
        {
          name: '人才招聘',
          path: '/recruit'
        }
      ],
    }
  },
  mounted(){
    if(this.$pubFun.getWidth()){
      this.$store.commit("changeNavStatus", true);
    }else{
      this.$store.commit("changeNavStatus", false);
    }
    window.onresize = () => {
      if(this.$pubFun.getWidth()){
        this.$store.commit("changeNavStatus", true);
      }else{
        this.$store.commit("changeNavStatus", false);
      }
    };

  },
  methods:{
    goPrivacy(){
      this.$router.push('/privacy');
    },
    // 导航跳转
    setLinkFun(path, id){
      if(path){
        let parameter = {
          path: path,
          id: id
        }
        localStorage.setItem('activePath', JSON.stringify(parameter));
        this.$router.push({
          path: path,
          query: {
            id: id
          }
        });
      }
    },

    /**
     * 添加预约
     */
    setAddAppointment() {
      const self = this
      // const RegExp = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/
      const RegExp = /^1[3456789]\d{9}$/

      if (!self.formPhone) {
        self.$message.closeAll();
        self.$message.warning('请输入手机号！')
        return
      }

      if (RegExp.test(self.formPhone) === false) {
        self.$message.warning('请正确输入手机号！')
        return
      }

      const param = {
        Details: self.formNeed,
        Phone: self.formPhone
      }
      AddAppointment(param).then(response => {
        if (response.Code !== 0) {
          self.$message.error(response.Message)
        } else {
          self.$message.success('预约成功！')
          self.formPhone = ''
          self.formNeed = ''
        }
      })
    }
  }
}
</script>

<style lang="less">
.foot{
  background: #181C5B;
  padding-top: 28px;
  color:#fff;
  position: relative;
  min-width: 1200px;
  width: 100%;
  overflow: hidden;
  z-index: 101;
  @media(max-width: 640px){
    min-width:auto;
  }
  @media(min-width: 1920px){
    max-width: 1920px;
    margin: 0 auto;
  }
  .line{
    position: absolute;
    top: 11%;
    width:100%;
    @media(min-width: 1920px){
      left: 10%;
      width:80%;
    }
    @media(max-width: 640px){
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
  .tip{
    font-size:48px;
    font-weight:500;
    text-align: center;
    @media (max-width: 640px){
      font-size:22px;
    }
  }
  .form{
    width:628px;
    margin: 20px auto 0;
    font-size: 18px;
    position: relative;
    @media (max-width: 640px){
      width:80%;
      font-size: 14px;
    }
    textarea{
      padding: 10px!important;
    }
    input, textarea{
      cursor: text;
      width:100%;
      margin: 20px 0;
      background-color:rgba(255,255,255,0.2);
      border-radius: 0;
      border:1px solid rgba(255,255,255,0.3);
      font-family: Microsoft YaHei!important;
      padding: 0 0 0 10px;
      color:#fff;
      font-size: 14px;
      &::-webkit-input-placeholder {
        color: #D9D9D9
      }
    }
    .book{
      width:130px;
      text-align: center;
      line-height: 44px;
      background: #fff;
      margin: 0 auto;
      color:#232323;
      position: absolute;
      bottom: 20px;
      left: 668px;
      cursor: pointer;
      &:hover{
        color:#3689FF;
      }
      @media (max-width: 1100px){
        position:relative;
        bottom: auto;
        left: auto;
      }
      @media (max-width: 640px){
        line-height: 34px;
      }
    }
  }
  .hot{
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    @media (max-width: 640px){
      font-size: 16px;
    }
  }
  .footGuide{
    display: flex;
    justify-content: space-between;
    padding: 40px 0 0;
    width: 1200px;
    margin:0 auto;
    @media (max-width: 640px){
      width:auto;
      flex-direction: column;
    }
    .footGuideContact{
      @media (max-width: 640px){
        width: 230px;
        margin: 0 auto 40px;
      }
      .footGuideContactTitle{
        font-size: 22px;
        font-weight: 400;
        @media (max-width: 640px){
          font-size: 18px;
        }
      }
      .footGuideContactInfo{
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
        @media (max-width: 640px){
          font-size: 12px;
        }
      }
    }
    .footGuidenNav{
      display: flex;
      font-size:  14px;
      position: relative;
      z-index:2;
      @media (max-width: 1060px){
        justify-content: space-around;
      }
      @media (max-width: 640px){
        font-size: 12px;
      }
      &>div{
        margin: 0 0 0 40px;
        @media (max-width: 640px){
          margin:0;
        }
        &:first-child, &:last-child{
          &>div{
            cursor: pointer;
          }
        }
        &>div{
          margin-bottom: 16px;
          &.footGuidenNavsub{
            cursor: pointer;
          }
        }
      }
    }
  }
  .Copyright{
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding: 32px 0 16px;
    align-items: center;
    @media (max-width: 640px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
