<template>
  <div :class="[$store.state.isPhone?'autoWidth':'', isHeaderTop?'':'white','headerDiv']">
    <div class="headerDivCenter">
      <router-link :to="{ path: '/index' }">
        <img class="logo" v-show="isHeaderTop" src="../assets/images/logo.png" alt="logo">
        <img class="logo" v-show="!isHeaderTop" src="../assets/images/blueLogo.png" alt="blueLogo">
      </router-link>

      <!-- pc导航 -->
      <div class="headerDivNav" v-if="!$store.state.isPhone">
        <div class="headerDivNavItem" v-for="(item, i) in navData" :key="'item'+i">
          <div 
            :class="[item.isActive?'headerDivNavItemActive':'', 'headerDivNavItemFirst']"
            @mouseover="selectStyle(i) "
            @mouseout="outStyle(i)"
             @click="setLinkFun(item.path, item.id)" 
          >
            {{item.name}}
          </div>
          <div 
            class="headerDivNavSub" v-show="item.show"
            @mouseover="selectStyle(i) "
            @mouseout="outStyle(i)"
          >
            <div 
              v-for="(sub, k) in item.children" 
              :key="'sub'+k" 
              @click="setLinkFun(sub.path, sub.id)" 
              :class="sub.isSubActive?'headerDivNavSubActive':''"
            >
              {{sub.name}}
            </div>
          </div>
        </div>
        <!-- <div class="headerDivNavItem jump" @click="jump"><span class="new">新</span><span class="used">/旧</span></div> -->
      </div>

      <!-- 手机导航 -->
      <div :class="[drawer?'activeMenu':'','menu-button']" @click="setNavFun" v-else>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <transition name="slide-fade">
        <div class="headerDivNavPhone" v-show="drawer && $store.state.isPhone">
          <div :class="[item.show || item.isActive?'blue':'','headerDivNavItem']" v-for="(item, i) in navData" :key="'item'+i">
            <div class="headerDivNavItemDiv" @click="setSubNavFun(i, item.show)">
              {{item.name}}
              <i :class="item.show?'el-icon-umpjiantoushang':'el-icon-umpjiantouxia'"></i>
            </div>
            <transition name="slide-fade">
              <div class="headerDivNavSub" v-show="item.show">
                <div 
                  v-for="(sub, k) in item.children" 
                  :key="'sub'+k" 
                  @click="setLinkFun(sub.path, sub.id)" 
                  :class="sub.isSubActive?'headerDivNavSubActive':''"
                >
                  {{sub.name}}
                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerDiv',
  data () {
    return {
      navData: [
        {
          name: '解决方案',
          path: "/solution",id: 'block0',
          children:[
            {path: "/solution",id: 'block0',name:'智慧教育'}, 
            {path: '/solution',id: 'block1',name:'智慧零售'}, 
            {path: '/solution',id: 'block2',name:'智慧运营'}, 
            {path: '/solution',id: 'block3',name:'智慧物业'}, 
            {path: '/solution',id: 'block4',name:'数字员工'}, 
            {path: '/solution',id: 'block5',name:'BPM'}
          ]
        },
        {
          name: '精选案例',
          path: '/case', id: '0',
          children:[
            {path: '/case', id: '0', name:'精选案例'}, 
            {path: '/case', id: '1', name:'零售+电商'}, 
            {path: '/case', id: '2', name:'教育'}, 
            {path: '/case', id: '4', name:'制造业'}, 
            {path: '/case', id: '5', name:'房地产'}, 
            {path: '/case', id: '7', name:'医疗'}, 
            {path: '/case', id: '11', name:'保险'}, 
            {path: '/case', id: '12', name:'其他'}
          ]
        },
        {
          name: '实例演示',
          path: '/showHow', id: '1',
          children:[
            {path: '/showHow', id: '1', name:'竞价系统'}, 
            {path: '/showHow', id: '2', name:'澳达斯公益'}, 
            {path: '/showHow', id: '3', name:'会议管理平台'}, 
            {path: '/showHow', id: '4', name:'访客系统'}, 
            {path: '/showHow', id: '5', name:'费用报销系统'}
          ]
        },
        {
          name: '关于我们',
          path: '/about',
          children:[
            {path: '/about',name:'企业介绍'},
            {path: '/recruit',name:'加入我们'},
            {path: '/news',name:'资讯动态'}
          ]
        }
      ],
      drawer: false,
      isHeaderTop: true,
      time: null
    }
  },
  watch:{
    $route(to){
      let parameter = {
        path: to.path,
        id: to.query.id
      }
      localStorage.setItem('activePath', JSON.stringify(parameter));
      let target = JSON.parse(localStorage.getItem('activePath'));
      this.getActiveNavPathFun(target.path, target.id);
    }
  },
  mounted(){
    if(this.$pubFun.getWidth()){
      this.$store.commit("changeNavStatus", true);
    }else{
      this.$store.commit("changeNavStatus", false);
    }
    window.onresize = () => {
      if(this.$pubFun.getWidth()){
        this.$store.commit("changeNavStatus", true);
      }else{
        this.$store.commit("changeNavStatus", false);
      }
    };

    window.addEventListener("scroll", this.setHeaderFun);

  },
  methods:{
    // 悬停显示二级导航
    selectStyle(index){
      clearTimeout(this.time);
      this.$set(this.navData[index], 'show', true);
    },
    // 移除隐藏二级导航
    outStyle(index){
      for(let j = 0; j < this.navData.length; j++){
        this.$set(this.navData[j], 'show', false);
      }
      this.time = setTimeout(()=>{
        this.$set(this.navData[index], 'show', false);
      },100);
    },
    // 手机端显示隐藏导航
    setNavFun(){
      this.drawer = !this.drawer;
    },
    // 显示隐藏二级菜单
    setSubNavFun(i, flag){
      for(let j = 0; j < this.navData.length; j++){
        this.$set(this.navData[j], 'show', false);
      }
      this.$set(this.navData[i], 'show', !flag); 
    },
    // 监听导航是否到顶
    setHeaderFun(){
      let scrollPos; 
      this.drawer = false; // 隐藏打开的导航
      for(let j = 0; j < this.navData.length; j++){
        this.$set(this.navData[j], 'show', false);
      }
      if (typeof window.pageYOffset != 'undefined') { 
        scrollPos = window.pageYOffset; 
      } else if (typeof document.compatMode != 'undefined' && document.compatMode != 'BackCompat') { 
        scrollPos = document.documentElement.scrollTop; 
      } else if (typeof document.body != 'undefined') { 
        scrollPos = document.body.scrollTop; 
      } 
      if(scrollPos === 0){
        this.isHeaderTop = true;
      }else{
        this.isHeaderTop = false;
      }
    },
    // 导航跳转
    setLinkFun(path, id){
      let parameter = {
        path: path,
        id: id
      }
      localStorage.setItem('activePath', JSON.stringify(parameter));
      this.$router.push({
        path: path,
        query: {
          id: id
        }
      });
    },
    // setLink(path, id){

    // },
    getActiveNavPathFun(path, id){
      for(let j = 0; j < this.navData.length; j++){
        this.$set(this.navData[j], 'isActive', false);
        this.$set(this.navData[j], 'show', false);
        this.drawer = false;
        for(let k = 0; k < this.navData[j].children.length; k++){
          this.$set(this.navData[j].children[k], 'isSubActive', false);
          if(path.indexOf(this.navData[j].children[k].path) > -1 && this.navData[j].children[k].path){
            if(!this.navData[j].children[k].hasOwnProperty('id') || this.navData[j].children[k].hasOwnProperty('id') && this.navData[j].children[k].id === id){
              this.$set(this.navData[j], 'isActive', true);
              this.$set(this.navData[j].children[k], 'isSubActive', true);
            }
          }
        }
      }
    },
    jump(){
      window.open('http://www.rdinfo.com.cn:8080','_self')
    }
  }
}
</script>

<style lang="less">
.headerDiv{
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  z-index: 102;
  transition: all 0.4s cubic-bezier(.74,.25,.27,.76);
  max-width:1920px;
  width: 100%;
  box-sizing: border-box;
  @media(max-width: 1200px){
    padding: 0 10px;
  }
  @media(min-width: 1920px){
    left:50%;
    top:0;
    transform: translateX(-50%);
  }
  &.white{
    background: #fff;
    box-shadow:0px 2px 30px 0px rgba(0,0,0,0.18);
    .used{
        color: #333;
        opacity: .5;
      }
    .headerDivNav{
      color: #333;
    }
    .icon{
      color:#333!important;
    }
    .menu-button{
      .bar{
        background:#333!important;
      }
    }
  }
  &.autoWidth{
    padding:0 20px;
    .icon{
      color: #fff;
      font-size: 30px;
    }
    .menu-button{
      cursor: pointer;
      .bar{
        width:20px;
        height: 3px;
        background:#fff;
        margin: 3px 0;
        border-radius: 10px;
        transition: all 0.4s cubic-bezier(.74,.25,.27,.76);
      }
      &.activeMenu{
        .bar{
          &:nth-child(1){
            transform:translateY(3px) rotate(-45deg);
          }
          &:nth-child(2){
            display: none;
          }
          &:nth-child(3){
            transform:translateY(-3px) rotate(45deg);
          }
        }
      }
    }
  }
  .headerDivCenter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width:1200px;
    margin: 0 auto;
    height: 70px;
    @media(max-width: 740px){
      max-width:auto;
      height: 44px;
      .logo{
        width:100px;
      }
    }
  }
  .logo{
    width:175px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(.74,.25,.27,.76);
  }
  /*pc导航*/
  .headerDivNav{
    color:#fff;
    height: 100%;
    line-height: 70px;
    .headerDivNavItem{
      display: inline-block;
      font-size: 20px;
      margin-left:50px;
      position: relative;
      &:hover{
        .headerDivNavItemFirst{
          color:#3689FF;
        }
      }
      @media (max-width:1200px){
        &:last-child{
          .headerDivNavSub{
            left: auto;
            right: 0;
            transform: translateX(0);
          }
        }
      }
      .headerDivNavItemFirst{
        cursor: pointer;
        transition: all 0.4s cubic-bezier(.74,.25,.27,.76);
        position: relative;
        &.headerDivNavItemActive{
          color:#3689FF;
          &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: #3689FF;
            width:100%;
            height: 6px;
          }
        }
      }
      .headerDivNavSub{
        width:195px;
        cursor: pointer;
        font-size: 18px;
        text-align: center;
        color: #333333;
        position: absolute;
        top:70px;
        left:50%;
        transform: translateX(-50%);
        box-shadow:0px 10px 21px 8px rgba(197,197,197,0.26);
        transition: all 0.4s cubic-bezier(.74,.25,.27,.76);
        div{
          background: #fff;
          cursor: pointer;
          line-height: 44px;
          &.headerDivNavSubActive{
            color: #3689FF;
          }
          &:hover{
            color:#3689FF;
          }
        }
      }
    }
  }
  /*手机导航*/
  .headerDivNavPhone{
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    background: #fff;
    line-height: 36px;
    font-size: 16px;
    .headerDivNavItem{
      &.blue{
        border-bottom:1px solid #eee;
        .headerDivNavItemDiv{
          background: #3689FF;
          color: #fff;
        }
      }
      .headerDivNavItemDiv{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        padding: 0 10px;
        border-bottom:1px solid #eee;
      }
      .headerDivNavSub{
        font-size: 14px;
        color: #666;
        &>div{
          padding: 0 10px;
          cursor: pointer;
          &.headerDivNavSubActive{
            color: #3689FF;
          }
        }
      }
    }
    i{
      font-weight: bold;
      cursor: pointer;
    }
  }

  .slide-fade-enter-active, .slide-fade-enter{
    transition: all 1s ease; 
  }
  .slide-fade-leave-active{
    transition: all 0.2s ease; 
    @media (max-width: 640px){
      transition: all 1s ease;
    }
  }
  .slide-fade-leave-active,.slide-fade-enter{
    max-height:0px;
    overflow: hidden;
  }
  .slide-fade-enter,.slide-fade-enter-active{
    max-height: 500px;
    overflow: hidden;
  }
  .new{
        color: #1890FF;
        cursor: pointer;
      }
      .used{
        color: #fff;
        opacity: .5;
        cursor: pointer;
      }

}
</style>
