import request from '@/utils/axiosTool'
// 获取资讯列表（分页）
export function websiteNews(data) {
  return request({
    url: '/WebsiteNews/GetPageList',
    method: 'post',
    data
  })
}
// 获取招聘列表（分页）
export function GetPageList(data) {
  return request({
    url: '/WebsiteRecruit/GetPageList',
    method: 'post',
    data
  })
}

// 添加预约
export function AddAppointment(data) {
  return request({
    url: '/WebsiteAppointment/AddAppointment',
    method: 'post',
    data
  })
}

// 添加实例申请
export function AddExampleApply(data) {
  return request({
    url: '/WebsiteExampleApply/AddExampleApply',
    method: 'post',
    data
  })
}

// 发验证码
export function SendAuthCode(data) {
  return request({
    url: '/WebsiteSms/SendAuthCode?phone=' + data.phone,
    method: 'post',
    data
  })
}

// 申请职位
export function AddRecruitApply(data) {
  return request({
    url: '/WebsiteRecruit/AddRecruitApply',
    method: 'post',
    data
  })
}

// 获取当前新闻详情 与 上一篇 下一篇
export function WebsiteNewsGetNewsList(data) {
  return request({
    url: '/WebsiteNews/GetNewsList',
    method: 'post',
    data
  })
}



